@import "src/themes/daikiri/styles/index.scss";
.home-advantages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    gap: space(1.063);
    align-items: center;
  }

  @include lg {
    flex-direction: row;
  }

  &--header {
    text-align: center !important;
    a {
      display: none;
    }

    @include sm {
      text-align: left !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: space(1.5);
      a {
        display: block;
        text-align: center;
        margin-top: 20px;
      }
    }

    @include lg {
      max-width: 30%;
      text-align: left !important;
      padding-right: 5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      a {
        display: block;
      }
    }

    h2 {
      text-align: center;
      margin-left: 1rem;
      margin-right: 1rem;

      @include sm {
        text-align: center;
        margin-left: unset !important;
        margin-right: unset !important;
        padding: 0;
      }
      @include lg {
        width: 88%;
        text-align: left;
      }
    }

    p {
      text-align: center;
      display: inline;
      font-size: 16px;

      @include sm {
        display: block;

        margin-bottom: 0 !important;
        max-width: 518px;
        text-align: center;
      }

      @include lg {
        text-align: left;
        font-size: 18px;
        margin-bottom: 16px !important;
      }

      .u-br-desktop {
        display: inline;
        @include lg {
          display: block;
        }
      }
    }
  }

  &--body {
    a {
      @include sm {
        display: none;
      }

      @include md {
        display: none;
      }
    }
  }

  &--narrow {
    .home-advantages--header {
      @include lg {
        max-width: 31%;
      }
    }
    .card-item {
      @include lg {
        height: 210px !important;
      }
    }
  }

  .cta-mobile {
    display: none;
    @include sm {
      display: block;
    }
    @include lg {
      display: none;
    }
  }

  .cta-desktop {
    display: none;

    @include lg {
      display: block;
    }
  }
}

/* landings SEM*/
.home-advantages--pdf-landing,
.home-advantages--vcard-landing {
  .button {
    padding: 1rem 0.5rem;
  }

  .cta-mobile {
    display: none;
    @include sm {
      display: block;
    }
    @include lg {
      display: none;
    }
  }

  .cta-desktop {
    display: none;

    @include lg {
      display: block;
    }
  }

  .home-advantages--header {
    width: 325px;

    margin: 0 auto;

    @include sm {
      width: 518px;
    }
    @include lg {
      max-width: 28%;
    }
  }
}
